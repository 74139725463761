import React from 'react';
// import Text from '../../components/Text';
import Container from '../../components/Container';
import BannerWrapper, { BannerContent } from './terms.style';
import { useTranslation } from 'react-i18next';

const TermsOfUse = () => {
  const { t } = useTranslation('terms');

  return (
    <BannerWrapper id='impressum'>
      <Container>
        <BannerContent>
          <h2>{t('header')}</h2>
          <p>{t('para1')}</p>
          <p>{t('para2')}</p>
          <p>{t('para3')}</p>
          <div>
            <p>{t('para4')}</p>
            <p>{t('para5')}</p>
            <ul>
              <li>
                <a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>
                  Google Play Services
                </a>
              </li>
              <li>
                <a href='https://firebase.google.com/terms/analytics' target='_blank' rel='noopener noreferrer'>
                  Google Analytics for Firebase
                </a>
              </li>
              <li>
                <a href='https://firebase.google.com/terms/crashlytics' target='_blank' rel='noopener noreferrer'>
                  Firebase Crashlytics
                </a>
              </li>
            </ul>
          </div>
          <p>{t('para6')}</p>
          <p></p>
          <p>{t('para7')}</p>
          <p>{t('para8')}</p>
          <p>{t('para9')}</p>
          <p>{t('para10')}</p>
          <p>
            <strong>{t('strong1')}</strong>
          </p>
          <p>{t('para11')}</p>
          <p>{t('para12')}</p>
          <p>
            <strong>{t('strong2')}</strong>
          </p>
          <p>{t('para13')}</p>
          <p>{t('para14')}&nbsp;
            <a href='https://app-privacy-policy-generator.firebaseapp.com/' target='_blank' rel='noopener noreferrer'>
              App Privacy Policy Generator
            </a>
          </p>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default TermsOfUse;
